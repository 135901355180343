/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. */
/* SPDX-License-Identifier: MIT-0 */

:root {
  --video-width: 88.4rem;
}

.video-elem {
  top: 0;
  background: #000;
}

@media (max-width: 480px) { /* Smaller Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) { /* Small Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) { /* Large Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) { /* Large Screens */
  :root {
    --video-width: 64rem;
  }
}
